<template>
    <div class="wrapper-modal">
        <form class="modal modal-exit">
            <span class="btn-close-modal" @click="closemodal"></span>
            <div class="modal-exit__content">
                <div class="modal-exit__photo"></div>
                <div class="modal-exit__info">
                    <div class="info-title-block">
                        <p class="info-title-block__title">Уже уходишь?</p>
                        <p class="info-title-block__subtitle">Забери подарок</p>
                        <p class="info-title-block__text">Чек-лист "Идеальное резюме начинающего
                            диджитал-маркетолога"!</p>
                    </div>
                    <div class="info-content-block">
                        <p class="info-content-block__title">Мы пришлем чек-лист на твой e-mail</p>
                        <input
                            type="email"
                            class="info-content-block__input"
                            placeholder="E-mail"
                        >
                        <input type="submit" value="Получить" class="button button_main info-content-block__button">
                        <p class="info-content-block__subtitle">
                            Нажимая кнопку "Получить", Вы соглашаетесь <br> с
                            <a
                                class="info-content-block__link"
                                href="./Политика_конфиденциальности.pdf"
                                target="_blank"
                            >Политикой конфеденциальности</a>
                        </p>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
export default {
    name: 'Vexit',
    methods: {
        closemodal() {
            this.$emit('closemodal');
        },
    }
};
</script>

<style scoped>
.modal-exit {
    max-width: 950px;
    width: 95%;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 5px 15px rgba(0, 0, 0, .5);

    @media (--desktop-only) {
        width: 100%;
    }

    &__content {
        display: flex;
        z-index: 50;
    }

    &__photo {

        @media (--tablet-only) {
            width: 50%;
            background-image: url("img/modal-1.jpg");
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }
    }

    &__info {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 35px 25px;
        background-color: #fff;

        @media (--tablet-only) {
            width: 50%;
            padding: 74px 56px;
        }
    }
}

.info-title-block {
    display: block;
    margin-bottom: 32px;

    &__title {
        font-family: var(--default);
        font-style: normal;
        font-weight: bold;
        font-size: 46px;
        line-height: 54px;
        color: #29abe2;
        margin-bottom: 16px;
        text-transform: uppercase;
    }

    &__subtitle {
        font-family: var(--default);
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
        letter-spacing: 0.005em;
        color: #29abe2;
        margin-bottom: 16px;
    }

    &__text {
        font-family: var(--default);
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 140%;
        letter-spacing: 0.005em;
        text-transform: uppercase;
        color: #f49d1a;
    }
}

.info-content-block {
    display: flex;
    flex-direction: column;

    &__title {
        font-family: var(--default);
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.005em;
        color: #222;
        margin-bottom: 16px;
    }

    &__input {
        padding: 10px 5px;
        border-bottom: 2px solid #757575;
        outline: none;
        font-family: var(--default);
        font-style: normal;
        font-weight: normal;
        line-height: 0;
        font-size: 14px;
        color: #1a1a1a;

        &:hover,
        &:focus {
            border-bottom: 2px solid #29abe2;
        }
    }

    &__button {
        margin-top: 24px;
        margin-bottom: 16px;
        max-width: 100%;
    }

    &__subtitle {
        font-family: var(--default);
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 14px;
        color: #828282;
        transition: 0.2s;

    }

    &__link {
        color: #29abe2;
        transition: 0.2s;

        &:hover {
            text-decoration: underline;
        }
    }
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #757575;
}

::-moz-placeholder { /* Firefox 19+ */
    color: #757575;
}

:-ms-input-placeholder { /* IE 10+ */
    color: #757575;
}

:-moz-placeholder { /* Firefox 18- */
    color: #757575;
}
</style>
