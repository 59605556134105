<template>
    <section class="section cost">
        <div class="wrapper">
            <header class="header cost__header">
                <h2 class="title cost__title">Стоимость обучения</h2>
            </header>
            <div class="cost__content">
                <article class="cost-card">
                    <h3 class="cost-card__title">Лайт</h3>
                    <div class="cost-card__body">
                        <ul>
                            <li>отдельный модуль (по выбору)</li>
                            <li>запись занятий с ограниченным доступом</li>
                            <li>разбор домашних заданий</li>
                        </ul>
                    </div>
                    <div class="cost-card__footer">
                        <div class="cost-card__footer-sale"><span></span></div>
                        <div class="cost-card__footer-cost"><span>12 999</span>₽</div>
                    </div>
                </article>
                <article class="cost-card">
                    <h3 class="cost-card__title">Эффективный</h3>
                    <div class="cost-card__body">
                        <ul>
                            <li>полный курс</li>
                            <li>запись занятий с неограниченным доступом</li>
                            <li>разбор домашних заданий</li>
                            <li>бонусные занятия</li>
                        </ul>
                    </div>
                    <div class="cost-card__footer">
                        <div class="cost-card__footer-sale"><span>28 999</span>₽</div>
                        <div class="cost-card__footer-cost"><span>20 999</span>₽</div>
                    </div>
                </article>
                <article class="cost-card">
                    <h3 class="cost-card__title">PRO</h3>
                    <div class="cost-card__body">
                        <ul>
                            <li>полный курс</li>
                            <li>запись занятий с неограниченным доступом</li>
                            <li>разбор домашних заданий</li>
                            <li>бонусные занятия</li>
                            <li>поддержка куратора после окончания курса 30 дней</li>
                        </ul>
                    </div>
                    <div class="cost-card__footer">
                        <div class="cost-card__footer-sale"><span></span></div>
                        <div class="cost-card__footer-cost"><span>46 999</span>₽</div>
                    </div>
                </article>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'Cost',
};
</script>

<style>
.cost {

    &__header {
        display: flex;

        .title::before {
            background-color: var(--card-main);
            bottom: -15px;
        }
    }

    &__content {
        width: 100%;
        margin-top: 70px;
        max-width: 380px;
        margin-left: auto;
        margin-right: auto;

        @media (--tablet-only) {
            display: grid;
            column-gap: 30px;
            row-gap: 30px;
            grid-template-columns: 1fr 1fr;
            max-width: initial;
            margin-left: initial;
            margin-right: initial;
        }

        @media (--desktop-only) {
            column-gap: 30px;
            grid-template-columns: repeat(3, 1fr);
        }
    }

    &-card {
        min-height: 430px;
        padding: 32px 24px;
        display: flex;
        flex-direction: column;
        margin-bottom: 24px;

        @media (--tablet-only) {
            min-height: 550px;
            margin-bottom: 0;
            padding: 60px 40px 40px 40px;
        }

        h3 {
            flex: 0 0 auto;
            text-align: center;
            font-family: var(--default);
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 32px;
            color: var(--color-text-main);

            @media (--tablet-only) {
                font-size: 34px;
                line-height: 40px;
            }
        }

        &__body {
            flex: 1 0 auto;
            margin-top: 32px;
            margin-bottom: 20px;
            width: 100%;
            border-top: 2px solid #f49d1a;

            @media (--tablet-only) {
                margin-top: 20px;
            }
        }

        ul {
            display: flex;
            flex-direction: column;
            margin-top: 24px;

            li:last-child {
                margin-bottom: 0;
            }

            li {
                font-family: var(--default);
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: var(--color-text-main);
                position: relative;
                padding-left: 30px;
                margin-bottom: 16px;

                &::before {
                    content: "";
                    position: absolute;
                    top: 5px;
                    left: 0;
                    width: 18px;
                    height: 14px;
                    display: block;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                }
            }
        }

        &:nth-child(odd) {
            background: #fdf0dd;

            li:before {
                background-image: url("img/ok.svg");
            }
        }

        &:nth-child(even) {
            background: #dbf1fa;

            .cost-card__body {
                border-top: 2px solid #1a8cbd;

                li:before {
                    background-image: url("img/ok-blue.svg");
                }
            }
        }

        &__footer {
            flex: 0 0 auto;
            font-family: var(--default);
            font-style: normal;
            font-weight: normal;
            font-size: 34px;
            line-height: 40px;
            text-align: center;
            color: var(--color-text-main);
            margin-top: auto;

            &-cost {

                span {
                    font-family: var(--default);
                    font-style: normal;
                    font-weight: 500;
                    font-size: 34px;
                    line-height: 40px;
                    text-align: center;
                    color: var(--color-text-main);
                }
            }

            &-sale {
                font-family: var(--default);
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                line-height: 30px;
                color: var(--color-text-main);
                text-decoration: line-through;
                margin-bottom: 8px;
            }
        }
    }

    &-card:last-child {
        margin-right: 0;
        margin-bottom: 0;
    }

    &-card:first-child {
        margin-left: 0;
    }

}
</style>
