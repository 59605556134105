<template>
    <section class="section reviews">
        <div class="wrapper reviews_bg">
            <header class="header reviews__header">
                <h2 class="title reviews__title">Отзывы студентов</h2>
            </header>
            <div class="reviews__content">
                <splide
                    class="reviews__slider slider"
                    :options="sliderReviews"
                >
                    <splide-slide class="slider__item">
                        <div class="card">
                            <div class="card__title">
                                <div class="card-avatar">
                                    <img
                                        src="./img/user-1.png"
                                        alt="avatar"
                                    >
                                </div>
                                <div class="card-name">Юлия Белобородкина</div>
                            </div>
                            <div class="card__content">
                                <div class="card__text">
                                    Я прошла весь курс от начала до конца. У меня уже есть образование в маркетинге,
                                    правда, в офлайн. На курсе я получила системные базовые знания в сфере
                                    интернет-маркетинга. Поняла, в какой сфере интернет-маркетинга мне больше нравится
                                    развиваться: это аналитика. Рекомендую данный курс для слушателей, особенно для
                                    офлайн-маркетологов, кто хочет перейти в диджитал-сферу.
                                </div>
                            </div>
                        </div>
                    </splide-slide>
                    <splide-slide class="slider__item">
                        <div class="card">
                            <div class="card__title">
                                <div class="card-avatar">
                                    <img
                                        src="./img/user-2.png"
                                        alt="avatar"
                                    >
                                </div>
                                <div class="card-name">Денис Метельский</div>
                            </div>
                            <div class="card__content">
                                <div class="card__text">
                                    Хороший, качественный курс. Больше всего зацепила возможность практики – у меня не
                                    было своего проекта, в рамках обучения создал на конструкторе образовательный
                                    лендинг и разработал стратегию продвижения его онлайн. Кому нужна практика –
                                    рекомендую пройти курс.
                                </div>
                            </div>
                        </div>
                    </splide-slide>
                    <splide-slide class="slider__item">
                        <div class="card">
                            <div class="card__title">
                                <div class="card-avatar">
                                    <img
                                        src="./img/user-3.png"
                                        alt="avatar"
                                    >
                                </div>
                                <div class="card-name">Полина Комарова</div>
                            </div>
                            <div class="card__content">
                                <div class="card__text">
                                    В процессе учебы в экономическом вузе поняла, что не хочу быть всю жизнь
                                    бухгалтером. Решила поучиться на интернет-маркетолога. Для меня, как для начинающего
                                    специалиста, было полезно узнать тонкости интернет-маркетинга. Особенно понравились
                                    практические блоки SMM и контент-маркетинг. Спасибо кураторам за оперативную
                                    обратную связь и комментарии к домашкам!
                                </div>
                            </div>
                        </div>
                    </splide-slide>
                    <splide-slide class="slider__item">
                        <div class="card">
                            <div class="card__title">
                                <div class="card-avatar">
                                    <img
                                        src="./img/user-4.png"
                                        alt="avatar"
                                    >
                                </div>
                                <div class="card-name">Илья Зубарь</div>
                            </div>
                            <div class="card__content">
                                <div class="card__text">
                                    Открою «секрет» —информацию с этого и любых других курсов по маркетингу можно найти
                                    в интернете в открытом доступе –есть много книг, ютуб-каналов, ресурсов и т.д. —
                                    информации хватит учиться лет на 15. Для меня ценность школы Диджитал скилл - - в
                                    системе и практике. Это единственный способ быстро узнать понемногу обо всем и сразу
                                    продвигать реальный проект.
                                </div>
                            </div>
                        </div>
                    </splide-slide>
                    <splide-slide class="slider__item">
                        <div class="card">
                            <div class="card__title">
                                <div class="card-avatar">
                                    <img
                                        src="./img/user-5.png"
                                        alt="avatar"
                                    >
                                </div>
                                <div class="card-name">Ольга Нефедова</div>
                            </div>
                            <div class="card__content">
                                <div class="card__text">
                                    Мне понравился в Диджитал скилл подход к обучению - мои домашние задания проверяли
                                    не «вчерашние студенты» этих же курсов, чем грешат многие курсы по
                                    интернет-маркетингу, а сами преподаватели. Блок по копирайтингу заставил переписать
                                    все тексты в моих соцсетях и Инстаграм. Решилась также начать вести видеоблог и
                                    продвигать его. Спасибо преподавателям за мотивацию и веру в меня!
                                </div>
                            </div>
                        </div>
                    </splide-slide>
                    <splide-slide class="slider__item">
                        <div class="card">
                            <div class="card__title">
                                <div class="card-avatar">
                                    <img
                                        src="./img/user-6.png"
                                        alt="avatar"
                                    >
                                </div>
                                <div class="card-name">Никита Коваленко</div>
                            </div>
                            <div class="card__content">
                                <div class="card__text">
                                    Записался вначале на бесплатное занятие, так как курсов в интернете много, а хороших
                                    – мало. В итоге записался в Digital skill на весь курс по интернет-маркетингу, уже в
                                    процессе учебы нашел место для практики, мой проект взлетел . Если не делать
                                    домашние задания, то курс не пройдешь - уроки не открываются  Каждое домашнее
                                    задание проверяет куратор, пишет комментарии для доработки. Система, видеоформат
                                    лекций, инфа без воды и обратная связь – то, что мне понравилось. Рекомендую Digital
                                    skill для получения практических навыков работы в интернет-маркетинге.
                                </div>
                            </div>
                        </div>
                    </splide-slide>
                </splide>
            </div>
        </div>
    </section>
</template>

<script>

import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import { Splide, SplideSlide } from '@splidejs/vue-splide';

export default {
    name: 'reviews',
    components: {
        Splide,
        SplideSlide,
    },
    data() {
        return {
            sliderReviews: {
                autoWidth: false,
                perPage: '2',
                type: 'loop',
                gap: '10px',
                breakpoints: {
                    1023: {
                        autoWidth: false,
                        gap: '20px',
                        perPage: 2,
                    },

                    767: {
                        perPage: 1,
                        gap: '45px',
                        arrows: false,
                    }
                },
            },
        };
    },
};

</script>

<style>
.reviews {

    &.section {
        padding: 0;
    }

    &__header {

        .title::before {
            background-color: var(--card-main);
            bottom: -15px;
        }
    }

    &__content {
        margin-top: 80px;

        @media (--tablet-only) {
            margin-top: 55px;
        }
    }

    .wrapper.reviews_bg {
        background: rgba(244, 157, 26, 0.1);
        padding: 40px 15px;

        @media (--tablet-only) {
            padding: 40px 15px 40px 65px;
        }

        @media (--decktop-only) {
            padding: 40px 15px 40px 82px;
        }
    }

    .splide__pagination__page {
        background-color: #f49d1a;
        border-radius: initial;
    }

    .splide__pagination {
        bottom: -0.5em;
    }

    .splide__arrows {
        position: absolute;
        right: 0;
        top: -40px;

        @media (--tablet-only) {
            top: -80px;
        }

        & .splide__arrow--prev {
            left: -150px;
            background: transparent;
            opacity: initial;
            background-image: url("img/arrow-left.svg");
            transition: 0.3s;

            &:hover {
                background-image: url("img/arrow-left-hover.svg");
            }

            svg {
                display: none;
            }
        }

        & .splide__arrow--next {
            left: -100px;
            background: transparent;
            opacity: initial;
            background-image: url("img/arrow-right.svg");
            transition: 0.3s;

            &:hover {
                background-image: url("img/arrow-right-hover.svg");
            }

            svg {
                display: none;
            }
        }
    }
}

.card {

    &__title {
        display: flex;
    }

    &-avatar {
        flex-shrink: 0;
        width: 120px;
        height: 120px;
        margin: 0 24px 0 0;
        overflow: hidden;
    }

    &-avatar img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &-name {
        font-family: var(--default);
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: var(--color-text-main);
        margin-top: 30px;
    }

    &__content {
        position: relative;
        top: -25px;
        left: 30px;
        background: #fff;
        box-shadow: 8px 8px 10px rgba(236, 148, 15, 0.2);
        padding: 24px;
        max-width: 650px;
        width: 86%;

        @media (--tablet-only) {
            width: 260px;
        }

        @media (--desktop-only) {
            top: -45px;
            left: 80px;
            width: 380px;
            padding: 16px 24px;
        }
    }

    &__text {
        font-family: var(--default);
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.005em;
        color: var(--color-text-main);
    }
}
</style>
