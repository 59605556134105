<template>
    <section class="section assurance">
        <div class="wrapper">
            <header class="header assurance__header">
                <h2 class="title assurance__title">Чему ты гарантированно научишься?</h2>
            </header>
            <div class="assurance__content">
                <div class="assurance__col">
                    <div class="assurance__card assurance__card_additional">
                        <div class="assurance__card-body">
                            Составишь портрет клиента и организуешь поток заявок с минимальным маркетинговым бюджетом
                        </div>
                    </div>
                    <div class="assurance__card assurance__card_main">
                        <div class="assurance__card-body">
                            Узнаешь фишки и лайфхаки написания продающих текстов, страниц компаний,  писем для email-рассылок
                        </div>
                    </div>
                    <div class="assurance__card assurance__card_additional">
                        <div class="assurance__card-body">
                            Разберёшься в основах SEO и аналитики: как это работает, какие показатели мониторить, что эффективно и что нет
                        </div>
                    </div>
                </div>
                <div class="assurance__col">
                    <div class="assurance__card assurance__card_main">
                        <div class="assurance__card-body">
                            Применишь работающие способы продвижения аккаунтов в популярных соцсетях
                        </div>
                    </div>
                    <div class="assurance__card assurance__card_additional">
                        <div class="assurance__card-body">
                            Разработаешь собственную стратегию продвижения, которая даст измеримый результат
                        </div>
                    </div>
                    <div class="assurance__card assurance__card_main">
                        <div class="assurance__card-body">
                            Разработаешь каждый этап создания посадочных страниц для продвижения разных видов продуктов
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'Assurance',
};
</script>

<style>

.assurance {
    display: block;

    &.section {
        padding-bottom: 50px;
    }

    &__content {
        max-width: 380px;
        width: 100%;
        margin-top: 70px;
        margin-left: auto;
        margin-right: auto;

        @media (--tablet-only) {
            max-width: initial;
        }

        @media (--desktop-only) {
            display: flex;
        }
    }

    & .wrapper {
        padding: 0;

        @media (--tablet-only) {
            padding-left: 20px;
            padding-right: 20px;
        }
    }

    &__title {
        padding-right: 16px;
        padding-left: 16px;
        color: var(--color-text-main);

        @media (--tablet-only) {
            padding-left: initial;
            padding-right: inherit;
        }

        &::before {
            background: #f49d1a;
            bottom: -15px;
            left: 20px!important;

            @media (--tablet-only) {
                left: initial!important;
            }
        }
    }

    &__col {
        position: relative;
        margin-bottom: 0;
        display: flex;
        flex-direction: column;

        @media (--tablet-only) {
            flex: 1;
            right: 150px;
            padding-right: 48px;
            align-items: center;
        }

        @media (--desktop-only) {
            right: 65px;
        }

        & .assurance__card_additional:first-child {
            margin-left: auto;

            @media (--tablet-only) {
                right: -180px;
                margin-left: initial;
            }
        }

        & .assurance__card_main:nth-child(2) {
            position: relative;
            margin-right: auto;
            z-index: 5;

            @media (--tablet-only) {
                margin-right: initial;
                top: -35px;
                left: 50px;
            }
        }

        & .assurance__card_additional:last-child {
            margin-left: auto;

            @media (--tablet-only) {
                top: -70px;
                right: -230px;
                margin-left: initial;
            }
        }

        & .assurance__card_main:first-child {
            position: relative;
            margin-right: auto;
            z-index: 5;

            @media (--tablet-only) {
                margin-right: initial;
                top: -20px;
                left: 0;
            }

            @media (--desktop-only) {
                left: -50px;
            }
        }

        & .assurance__card_additional:nth-child(2) {
            position: relative;
            margin-left: auto;

            @media (--tablet-only) {
                margin-left: initial;
                top: -50px;
                left: 83px;
            }
        }

        & .assurance__card_main:last-child {
            position: relative;
            margin-right: auto;
            z-index: 5;

            @media (--tablet-only) {
                margin-right: initial;
                left: 0;
                top: -85px;
            }
        }
    }

    &__col + &__col {
        padding-left: 0;
        padding-right: 0;

        @media (--tablet-only) {
            padding-left: 48px;
            padding-right: 0;
        }
    }

    &__card {
        max-width: 288px;
        width: 100%;
        padding: 24px 26px;
        margin-bottom: 16px;

        @media (--tablet-only) {
            max-width: 359px;
            padding: 70px 62px;
            margin-bottom: 0;
        }
    }

    &__card_main {
        background-color: var(--card-main);
        color: var(--color-text-main);
    }

    &__card_additional {
        position: relative;
        background-color: var(--card-additional);
        color: var(--color-text-additional);
    }

    &__card-body {
        font-family: var(--default);
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
    }
}
</style>
