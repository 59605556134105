<template>
    <form @submit="validateForm">
        <div
            class="wrapper-modal"
            @click="closemodalfree"
        ></div>
        <div class="modal modal-free">
            <button
                class="btn-close-modal"
                @click="closemodalfree"
            ></button>
            <div class="modal-free__content">
                <div class="modal-free__photo"></div>
                <div class="modal-free__info">
                    <div class="info-title-block">
                        <p class="info-title-block__title">Запишись на бесплатное занятие</p>
                    </div>
                    <div class="info-content-block">
                        <input
                            type="text"
                            class="info-content-block__input"
                            placeholder="Имя"
                            v-model="form.name"
                        >
                        <input
                            type="tel"
                            class="info-content-block__input"
                            placeholder="Номер телефона"
                            v-model="form.tel"
                        >
                        <input
                            type="email"
                            class="info-content-block__input"
                            placeholder="E-mail"
                            autocomplete="off"
                            v-model="form.email"
                        >

                        <ul v-if="form.errors.length">
                            <li class="info-content-block__error" v-for="error in form.errors" :key="error">{{ error }}</li>
                        </ul>

                        <button
                            type="submit"
                            class="button button_main info-content-block__button"
                            @click="hideResul"
                        >Отправить заявку</button>
                        <div class="info-content-block__subtitle">
                            <input
                                class="custom-checkbox"
                                type="checkbox"
                                id="1"
                                name="1"
                                checked
                            >
                            <label for="1">Вы даете согласие на обработку персональных<br> данных и соглашаетесь c политикой
                                конфиденциальности</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Successfully
            v-if="isModalSuccessfully"
            @closemodal="closemodal"/>

        <Error v-if="isModalSuccessfully"
               @closemodal="closemodal"/>
    </form>

</template>

<script>
import Successfully from "@/components/PopUp/result/Successfully/Successfully"
import Error from "@/components/PopUp/result/Error/Error"

export default {
    name: 'FreeLesson',

    components: {
        Successfully,
        Error,
    },

    data() {
        return {
            isModalSuccessfully: false,
            form: {
                errors: [],
                name: "",
                email: "",
                tel: "",
            },
        };
    },

    methods: {
        closemodalfree() {
            this.$emit("closemodalfree");
            this.isModalSuccessfully = true;
        },

        closemodal() {
            this.isModalSuccessfully = false;
        },

        hideResul() {
            this.isModalSuccessfully = true;
        },

        validateForm(e) {
            this.form.errors = [];

            if (!this.form.name) {
                this.form.errors.push('Укажите имя');
            }
            if (!this.form.tel) {
                this.form.errors.push('Введите номер телефона');
            }
            if (!this.form.email) {
                this.form.errors.push('Укажите электронную почту');
            } else if (!this.validEmail(this.form.email)) {
                this.form.errors.push('Укажите корректный адрес электронной почты.');
            }

            if (!this.form.errors.length) {
                return true;
            }

            e.preventDefault();
        },

        validEmail: function (email) {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        }
    },
};
</script>

<style scoped>
.modal-free {
    max-width: 950px;
    width: 95%;
    background-color: #fff;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
    z-index: 100;

    @media (--desktop-only) {
        width: 100%;
    }

    &__content {
        display: flex;
    }

    &__photo {

        @media (--tablet-only) {
            width: 50%;
            background-image: url("img/modal-2.jpg");
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }
    }

    &__info {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 35px 25px;
        background-color: #fff;

        @media (--tablet-only) {
            width: 50%;
            padding: 74px 56px;
        }
    }

    .info-title-block {
        display: block;
        margin-bottom: 32px;

        &__title {
            font-family: var(--default);
            font-style: normal;
            font-weight: bold;
            font-size: 32px;
            line-height: 48px;
            color: #29abe2;
        }
    }

    .info-content-block {
        display: flex;
        flex-direction: column;

        &__input {
            padding: 10px 5px;
            border-bottom: 2px solid #757575;
            outline: none;
            font-family: var(--default);
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            color: #1a1a1a;
            margin-bottom: 32px;
            transition: 0.3s;

            &:hover,
            &:focus {
                border-bottom: 2px solid #29abe2;
            }
        }

        input[type="email"] {
            margin-bottom: 0;
        }

        &__error {
            display: flex;
            align-items: center;
            color: red;
            margin-top: 14px;
            font-family: var(--default);
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            margin-bottom: 8px;
        }

        &__error:last-child {
            margin-bottom: 0;
        }

        &__error::before {
            content: "";
            width: 24px;
            height: 24px;
            display: block;
            margin-right: 10px;
            background-image: url("img/error.svg");
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }

        &__button {
            margin-top: 24px;
            margin-bottom: 16px;
            max-width: 100%;
        }

        &__subtitle {
            font-family: var(--default);
            font-style: normal;
            font-weight: 300;
            font-size: 12px;
            line-height: 14px;
            color: #828282;
            transition: 0.2s;
            display: flex;

            input {
                margin-right: 20px;
            }
        }
    }

    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: #757575;
    }

    ::-moz-placeholder { /* Firefox 19+ */
        color: #757575;
    }

    :-ms-input-placeholder { /* IE 10+ */
        color: #757575;
    }

    :-moz-placeholder { /* Firefox 18- */
        color: #757575;
    }

    .custom-checkbox {
        position: absolute;
        z-index: -1;
        opacity: 0;
        display: block;
        width: 0;
        height: 0;
    }

    /* для элемента label, связанного с .custom-checkbox */
    .custom-checkbox + label {
        display: inline-flex;
        user-select: none;
    }

    /* создание в label псевдоэлемента before со следующими стилями */
    .custom-checkbox + label::before {
        content: "";
        display: inline-block;
        width: 18px;
        height: 18px;
        flex-shrink: 0;
        flex-grow: 0;
        border: 1px solid #adb5bd;
        margin-right: 19px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 50% 50%;
    }

    /* стили при наведении курсора на checkbox */
    .custom-checkbox:not(:disabled):not(:checked) + label:hover::before {
        border-color: #b3d7ff;
    }

    /* стили для активного чекбокса (при нажатии на него) */
    .custom-checkbox:not(:disabled):active + label::before {
        background-color: #b3d7ff;
        border-color: #b3d7ff;
    }

    /* стили для чекбокса, находящегося в фокусе и не находящегося в состоянии checked */
    .custom-checkbox:focus:not(:checked) + label::before {
        border-color: #80bdff;
    }

    /* стили для чекбокса, находящегося в состоянии checked */
    .custom-checkbox:checked + label::before {
        background-image: url("img/active-arrow.svg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    /* стили для чекбокса, находящегося в состоянии disabled */
    .custom-checkbox:disabled + label::before {
        background-color: #e9ecef;
    }
}
</style>
