<template>
    <section class="description">
        <div class="wrapper">
            <div class="content description__content">
                <div class="content__col">
                    <div class="description__title">Пришло время освоить digital-профессию и стать востребованным
                        специалистом!
                    </div>
                    <ul class="description__list">
                        <li class="description__item">А что, если мы скажем, что такие специалисты нужны всем, и ты
                            будешь нарасхват?
                        </li>
                        <li class="description__item">А что, если мы скажем, что обучиться такой профессии можно за 2,5
                            месяца?
                        </li>
                        <li class="description__item">А что, если мы скажем, что поможем тебе, и сразу после курса ты
                            начнёшь работать?
                        </li>
                    </ul>
                    <p class="description__subtitle">Врывайся на наш онлайн-курс «С нуля до уверенной работы
                        интернет-маркетологом» — и стань крутым
                        digital-специалистом!</p>
                </div>
                <div class="content__col">
                    <div class="description__box">
                        <div class="description-card description-card_additional">
                            <div class="description-card__title">Онлайн-формат</div>
                            <div class="description-card__subtitle">
                                Учись, когда хочешь и где хочешь, в своём темпе и без жестких дедлайнов.
                            </div>
                        </div>
                        <div class="description-card description-card_position description-card_additional">
                            <div class="description-card__title">Пракическая направленность</div>
                            <div class="description-card__subtitle">
                                Практикуйся на реальных проектах и применяй реальные кейсы
                            </div>
                        </div>
                        <div class="description-card description-card_main">
                            <div class="description-card__title">Обратная связь</div>
                            <div class="description-card__subtitle">
                                Получай обратную связь от преподавателя и учись на своих ошибках
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'Description',
};
</script>

<style>
.description {
    position: relative;

    &::before {
        display: none;

        @media (--tablet-only) {
            content: "";
            background-color: #dbf1fa;
            display: block;
            width: 30%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    &__content {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        @media (--tablet-only) {
            flex-wrap: nowrap;
            justify-content: initial;
        }

        .content__col {
            z-index: 5;
        }

        .content__col:nth-child(2) {
            display: flex;
            justify-content: center;
            z-index: 5;
        }
    }

    & .content {

        &__col {
            padding: 32px 0;

            @media (--tablet-only) {
                padding: 80px 0;
            }

            @media (--desktop-only) {
                padding: 148px 0;
            }
        }

        &__col:first-child {
            background-color: #dbf1fa;
            margin-right: -16px;
            margin-left: -16px;
            padding-right: 16px;
            padding-left: 16px;

            @media (--tablet-only) {
                padding-right: 30px;
                margin-right: initial;
                margin-left: initial;
                padding-left: initial;
            }
        }

        &__col:last-child {
            margin: 0 auto;
        }
    }

    &__title {
        max-width: 345px;
        font-family: var(--default);
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 30px;
        color: var(--color-text-main);
    }

    &__list {
        margin-top: 32px;
        margin-bottom: 25px;
        padding-left: 30px;
    }

    &__item {
        list-style: disc;
    }

    &__item,
    &__subtitle {
        font-family: var(--default);
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        color: var(--color-text-main);
    }

    &__box {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;

        @media (--tablet-only) {
            max-width: 400px;
            width: 100%;
            padding-left: 20px;
        }

        @media (--desktop-only) {
            display: flex;
            flex-direction: initial;
            width: initial;
            max-width: initial;
        }

        .description-card:first-child {

            @media (--tablet-only) {
                margin-right: 50px;
            }
        }
    }
}

.description-card {
    flex-shrink: 0;
    width: 232px;
    height: 224px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--color-text-additional);
    font-family: var(--default);
    margin: 10px;

    @media (--desktop-only) {
        width: 260px;
        height: 256px;
        padding: 20px 40px;
    }

    &__title {
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 32px;
        margin-bottom: 16px;
    }

    &__subtitle {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
    }

    &_main {
        position: relative;
        top: -45px;
        left: 25px;
        background: var(--card-main);
        color: var(--color-text-main);
        margin-left: auto;

        @media (--tablet-only) {
            top: 0;
            left: 0;
        }

        @media (--desktop-only) {
            position: absolute;
            top: 200px;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &_additional {
        background: var(--card-additional);
    }

    &_position {
        margin-right: auto;
        order: 2;
        z-index: 2;
        position: relative;
        top: -12%;
        left: -6%;

        @media (--tablet-only) {
            position: static;
            z-index: 0;
        }

        @media (--desktop-only) {
            margin-top: -25px;
        }
    }
}
</style>
