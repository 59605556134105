<template>
    <section class="section team">
        <div id="team" style="position:relative; top:-170px;"></div>
        <div class="wrapper">
            <header class="header team__header">
                <h2 class="title team__title">Преподаватели курса</h2>
                <p class="team__subtitle">Практикующие интернет-маркетологи, которые хотят поделиться опытом, заразить
                    тебя энтузиазмом и сделать мир чуточку лучше</p>
            </header>
            <div class="team__content">
                <splide
                    class="team__slider slider"
                    :options="sliderTeam"
                >
                    <splide-slide class="slider__item">
                        <div class="team-card">
                            <div class="team-card-avatar">
                                <img
                                    src="./img/team-1.jpg"
                                    alt="avatar"
                                >
                            </div>
                            <div class="team-card__content">
                                <div class="team-card__content-text">
                                    <div class="team-card__title">Анастасия Бабина</div>
                                    <div class="team-card__subtitle">редактор, UX-писатель</div>
                                    <ul class="team-card__list">
                                        <li class="team-card__item"><span>Опыт работы в маркетинге — 6 лет</span></li>
                                        <li class="team-card__item"><span>Пишу и редактирую инфостатьи, продающие тексты,
                                            тексты для лендингов и email-рассылок</span></li>
                                        <li class="team-card__item"><span>Окончила курс “UX-писатель”</span></li>
                                        <li class="team-card__item">
                                            <span>Мои тексты повышают конверсию сайта на 10%.</span></li>
                                        <li class="team-card__item"><span>Умею в инфостиль и разбираюсь, какой текст написать
                                            на кнопке, чтобы вы попали туда, куда нужно.</span></li>
                                    </ul>
                                </div>
                                <div class="team-card__content-bg "></div>
                            </div>
                        </div>
                    </splide-slide>
                    <splide-slide class="slider__item">
                        <div class="team-card">
                            <div class="team-card-avatar">
                                <img
                                    src="./img/team-2.jpg"
                                    alt="avatar"
                                >
                            </div>
                            <div class="team-card__content">
                                <div class="team-card__content-text">
                                    <div class="team-card__title">Бузунова Яна</div>
                                    <div class="team-card__subtitle">SMM-специалист, маркетолог</div>
                                    <ul class="team-card__list">
                                        <li class="team-card__item"><span>Опыт работы в маркетинге — 6 лет</span></li>
                                        <li class="team-card__item"><span>Создаю репутацию продукта</span></li>
                                        <li class="team-card__item">
                                            <span>Работаю с отзывами и отрабатываю негатив</span></li>
                                        <li class="team-card__item"><span>Разрабатываю цепочки писем для клиентов: открываемость и переходы по ссылкам — от 30%</span>
                                        </li>
                                        <li class="team-card__item"><span>Работаю с соцсетями: “Вконтакте”, Facebook, Instagram, Tik-Tok</span>
                                        </li>
                                        <li class="team-card__item"><span>Знаю все болевые точки клиента и умею с ними работать</span>
                                        </li>
                                    </ul>
                                </div>
                                <div class="team-card__content-bg "></div>
                            </div>
                        </div>
                    </splide-slide>
                    <splide-slide class="slider__item">
                        <div class="team-card">
                            <div class="team-card-avatar">
                                <img
                                    src="./img/team-3.jpg"
                                    alt="avatar"
                                >
                            </div>
                            <div class="team-card__content">
                                <div class="team-card__content-text">
                                    <div class="team-card__title">Артем Щелкунов</div>
                                    <div class="team-card__subtitle">SEO-специалист, WEB-аналитик</div>
                                    <ul class="team-card__list">
                                        <li class="team-card__item">
                                            <span>Опыт работы в интернет-маркетинге более 5 лет</span></li>
                                        <li class="team-card__item"><span>Создавал свои e-commerce проекты для стран СНГ: от идеи до продаж</span>
                                        </li>
                                        <li class="team-card__item"><span>Занимаюсь SEO продвижением сайтов (внешняя, внутренняя, техническая оптимизация, администрирование), контекстной рекламой и WEB-аналитикой.</span>
                                        </li>
                                    </ul>
                                </div>
                                <div class="team-card__content-bg "></div>
                            </div>
                        </div>
                    </splide-slide>
                    <splide-slide class="slider__item">
                        <div class="team-card">
                            <div class="team-card-avatar">
                                <img
                                    src="./img/team-4.jpg"
                                    alt="avatar"
                                >
                            </div>
                            <div class="team-card__content">
                                <div class="team-card__content-text">
                                    <div class="team-card__title">Надежда Нашиванкина</div>
                                    <div class="team-card__subtitle">SEO-специалист</div>
                                    <ul class="team-card__list">
                                        <li class="team-card__item"><span>Опыт работы с сайтами более 10 лет, в SEO - 6 лет</span>
                                        </li>
                                        <li class="team-card__item"><span>Продвигаю сайты в Яндекс и Google СНГ</span>
                                        </li>
                                        <li class="team-card__item"><span>Каждый месяц на продвигаемые мной сайты приходит более 1 500 000 посетителей из поиска</span>
                                        </li>
                                        <li class="team-card__item"><span>Считаю, что в системах аналитики можно найти большую часть решений проблем на сайте</span>
                                        </li>
                                    </ul>
                                </div>
                                <div class="team-card__content-bg "></div>
                            </div>
                        </div>
                    </splide-slide>
                    <splide-slide class="slider__item">
                        <div class="team-card">
                            <div class="team-card-avatar">
                                <img
                                    src="./img/team-5.jpg"
                                    alt="avatar"
                                >
                            </div>
                            <div class="team-card__content">
                                <div class="team-card__content-text">
                                    <div class="team-card__title">Ирина Гайдук</div>
                                    <div class="team-card__subtitle">практикующий маркетолог</div>
                                    <ul class="team-card__list">
                                        <li class="team-card__item"><span>Опыт работы в маркетинге — 12 лет</span></li>
                                        <li class="team-card__item"><span>Училась в СПбПУ на курсе “Интернет-маркетинг”, стажировалась в Германии, Cambrige CAE</span>
                                        </li>
                                        <li class="team-card__item"><span>Каждый месяц на продвигаемые мной сайты приходит более 1 500 000 посетителей из поиска</span>
                                        </li>
                                        <li class="team-card__item"><span>Занимаюсь исследованием и разработкой стратегий продвижения продукта в контент-маркетинге, разработкой лендингов и аналитикой данных</span>
                                        </li>
                                    </ul>
                                </div>
                                <div class="team-card__content-bg"></div>
                            </div>
                        </div>
                    </splide-slide>
                    <splide-slide class="slider__item">
                        <div class="team-card">
                            <div class="team-card-avatar">
                                <img
                                    src="./img/team-6.jpg"
                                    alt="avatar"
                                >
                            </div>
                            <div class="team-card__content">
                                <div class="team-card__content-text">
                                    <div class="team-card__title">Махнева Елизавета</div>
                                    <div class="team-card__subtitle">digital-маркетолог</div>
                                    <ul class="team-card__list">
                                        <li class="team-card__item"><span>Практикующий интернет-маркетолог с опытом работы более 10 лет.</span>
                                        </li>
                                        <li class="team-card__item"><span>Высшее экономическое образование по специальности «Маркетолог-экономист»</span>
                                        </li>
                                        <li class="team-card__item"><span>В своём профессиональном пути прошла все этапы от мерчендайзера и промоутера до ведущего маркетолога и сооснователя бизнеса </span>
                                        </li>
                                        <li class="team-card__item">
                                            <span>В работе всегда опираюсь на стратегию и цели</span></li>
                                    </ul>
                                </div>
                                <div class="team-card__content-bg "></div>
                            </div>
                        </div>
                    </splide-slide>
                </splide>
            </div>
        </div>
        <div class="team_bg"></div>
    </section>
</template>

<script>

import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import { Splide, SplideSlide } from '@splidejs/vue-splide';

export default {
    name: 'team',
    components: {
        Splide,
        SplideSlide,
    },
    data() {
        return {
            sliderTeam: {
                autoWidth: true,
                height: '700px',
                perPage: '3',
                type: 'loop',
                gap: '75px',
                breakpoints: {
                    1023: {
                        autoWidth: true,
                        gap: '65px',
                        perPage: 2,
                    },

                    767: {
                        autoWidth: true,
                        perPage: 1,
                        arrows: false,
                        gap: '65px'
                    }
                },
            },
        };
    },
};

</script>

<style >
.team {
    position: relative;

    & .wrapper {
        width: 100%;
        padding-right: 0;
    }

    &_bg {
        width: 100%;
        position: absolute;
        height: 60%;
        bottom: 0;
        background: #dbf1fa;
        z-index: 0;

        @media (--tablet-only) {
            height: 50%;
        }
    }

    &__header {

        padding-right: 16px;

        @media (--desktop-only) {
            padding-right: initial;
        }

        .title::before {
            background-color: var(--card-additional);
            bottom: -15px;
        }
    }

    &__subtitle {
        font-family: var(--default);
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: var(--color-text-main);
        margin-top: 32px;
        max-width: 655px;
        width: 100%;

        @media (--desktop-only) {
            font-size: 18px;
            line-height: 24px;
            margin-top: 47px;
        }
    }

    &__content {
        margin-top: 70px;
    }

    &__slider {
        z-index: 10;
    }

    .splide__track {
        padding-left: 30px;
    }

    .splide__pagination__page {
        background-color: #29abe2;
        border-radius: initial;
    }

    .splide__pagination {
        bottom: -50px;
    }

    .splide__arrows {
        position: absolute;
        right: 0;
        top: -35px;

        @media (--tablet-only) {
            top: -150px;
        }

        & .splide__arrow--prev {
            left: -150px;
            opacity: initial;
            background: transparent url("img/team-arrow-left.svg") no-repeat center;
            transition: 0.3s;

            &:hover {
                background-image: url("img/team-arrow-left-hover.svg");
            }

            svg {
                display: none;
            }
        }

        & .splide__arrow--next {
            left: -100px;
            opacity: initial;
            background: transparent url("img/team-arrow-right.svg") no-repeat center;
            transition: 0.3s;

            &:hover {
                background-image: url("img/team-arrow-right-hover.svg");
            }

            svg {
                display: none;
            }
        }
    }
}

.team-card {
    display: flex;
    flex-direction: column;

    &-avatar {
        flex-shrink: 0;
        max-width: 247px;
        width: 100%;
        margin: 0 20px 0 0;
        overflow: hidden;

        @media (--tablet-only) {
            max-width: 296px;
            width: 100%;
        }
    }

    &-avatar img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__content {
        position: relative;
        display: flex;
        flex-direction: column;
        margin-left: -30px;
    }

    &__content-text {
        position: absolute;
        background-color: #fff;
        padding: 16px;
        max-width: 275px;
        width: 100%;
        top: 16px;
        left: 16px;
        box-shadow: 4px 4px 10px rgba(0, 112, 157, 0.2);

        @media (--tablet-only) {
            max-width: 344px;
            width: 100%;
            padding: 24px;
        }
    }

    &__title {
        font-family: var(--default);
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        color: var(--color-text-main);

        @media (--tablet-only) {
            margin-bottom: 8px;
        }
    }

    &__subtitle {
        font-family: var(--default);
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.005em;
        color: #4f4f4f;
        margin-bottom: 16px;

    }

    &__list {
        display: flex;
        flex-direction: column;
        padding-left: 20px;

        li {
            color: #29abe2;
        }

        li span {
            color: #222;
            font-family: var(--default);
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0.005em;
        }
    }

    &__item {
        list-style: disc;
        margin-bottom: 8px;
    }

    &__content-bg {
        max-width: 277px;
        width: 100%;
        height: 290px;
        background-color: var(--card-additional);

        @media (--tablet-only) {
            max-width: 326px;
            width: 100%;
        }
    }
}
</style>
