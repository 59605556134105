<template>
    <section class="steps">
        <div class="wrapper">
            <div class="content steps__content">
                <div class="content__col">
                    <p class="steps__title">Кому подойдет курс</p>
                    <ul>
                        <li>тем, кто мечтает переехать в другую страну, но привязан к месту работы</li>
                        <li>студентам, которые хотят найти своё призвание</li>
                        <li>всем, кто решил работать удаленно, но не знает, с чего начать</li>
                        <li>и всем, кто стремится зарабатывать больше</li>
                    </ul>
                    <div class="steps__box">
                        <button
                            class="button button_main steps__button"
                            @click="showModalFree"
                        >Получи бесплатное занятие</button>
                    </div>
                </div>
                <div class="content__col">
                    <div class="steps__photo">
                        <img
                            src="./img/steps.jpg"
                            alt="steps"
                            class="steps__img"
                        >
                    </div>
                </div>
            </div>
            <div class="steps__mobile"></div>
        </div>
    </section>
    <FreeLesson
        v-if="isModalFreeLesson"
        @closemodalfree="closemodalfree"/>
</template>

<script>
import FreeLesson from '@/components/PopUp/FreeLesson/FreeLesson';

export default {
    name: 'Steps',

    components: {
        FreeLesson,
    },

    data() {
        return {
            isModalFreeLesson: false,
        };
    },

    methods: {
        showModalFree() {
            this.isModalFreeLesson = true;
        },

        closemodalfree() {
            this.isModalFreeLesson = false;
        },
    }
};
</script>

<style>
.steps {
    position: relative;

    &::before {
        display: none;

        @media (--desktop-only) {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            width: 40%;
            height: 100%;
            display: block;
            background-color: #2aaae3;
        }
    }

    & .wrapper {
        padding-right: 0;
        padding-left: 0;

        @media (--tablet-only) {
            padding-right: 16px;
            padding-left: 16px;
        }
    }

    &__mobile {
        max-width: 767px;
        width: 100%;
        height: 468px;
        background-image: url("img/steps-mobile.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        @media (--tablet-only) {
            display: none;
        }
    }

    &__content {
        width: 100%;
        padding-right: 16px;
        padding-left: 16px;

        @media (--tablet-only) {
            display: flex;
            justify-content: space-between;
            padding-right: 0;
            padding-left: 0;
        }

        & .content__col {
            z-index: 10;

            ul {
                margin-top: 16px;
                display: flex;
                flex-direction: column;
                width: 100%;
                margin-bottom: 30px;
                padding-left: 30px;

                @media (--tablet-only) {
                    margin-top: 40px;
                    margin-bottom: 60px;
                }

                li:nth-child(even) {
                    color: #136B90;
                }
            }

            li {
                margin-bottom: 8px;
                font-family: var(--default);
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 24px;
                color: #4f4f4f;
                letter-spacing: 0.005em;
                list-style: disc;

                @media (--tablet-only) {
                    margin-bottom: 16px;
                }
            }
        }
    }

    & .content {

        &__col:first-child {
            padding-right: 16px;
            margin-bottom: 60px;

            @media (--tablet-only) {
                width: 100%;
                padding-top: 80px;
                padding-right: 30px;
                margin-bottom: 60px;
            }

            @media (--desktop-only) {
                width: 100%;
                max-width: 390px;
                margin-bottom: 0;
            }
        }
    }

    &__title {
        font-family: var(--default);
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        color: #1A1A1A;

        @media (--tablet-only) {
            font-size: 24px;
            line-height: 32px;
        }
    }

    &__photo {
        margin-left: -16px;
        margin-right: -16px;
        height: 100%;

        @media (--tablet-only) {
            width: 100%;
            margin-left: initial;
            margin-right: initial;
            overflow: hidden;
        }
    }

    &__img {
        width: 100%;
        height: 100%;
        display: none;
        object-fit: cover;

        @media (--tablet-only) {
            display: block;
        }
    }
}
</style>
