<template>
    <Vheader />
    <main>
        <Banner />
        <Description />
        <Steps />
        <Assurance />
        <Format />
        <Programm />
        <Reviews />
        <Team />
        <Cost />
        <Video />
        <Questions />
        <Form />
        <Vfooter />
        <Vexit
            v-if="isVexit"
            @closemodal="closemodal"/>
    </main>
</template>

<script>
import Vheader from './components/Header/Vheader.vue';
import Banner from './components/Banner/Banner.vue';
import Description from './components/Description/Description.vue';
import Steps from './components/Steps/Steps.vue';
import Assurance from '@/components/Assurance/Assurance';
import Programm from '@/components/Programm/Programm';
import Questions from '@/components/Questions/Questions';
import Cost from '@/components/Cost/Cost';
import Format from '@/components/Format/Format';
import Video from '@/components/Video/Video';
import Reviews from '@/components/SplideSlider/Reviews/Reviews';
import Team from '@/components/SplideSlider/Team/Team';
import Vfooter from '@/components/Footer/Vfooter';
import Form from '@/components/Form/Form';
import Vexit from '@/components/PopUp/Exit/Vexit';

export default {
    name: 'App',
    components: {
        Vexit,
        Form,
        Vfooter,
        Team,
        Reviews,
        Video,
        Format,
        Cost,
        Questions,
        Programm,
        Assurance,
        Vheader,
        Banner,
        Description,
        Steps,
    },

    data() {
        return {
            isModalConsul: false,
            isVexit: false,
            isVexitDisable: false,
        };
    },

    methods: {
        closemodal() {
            this.isVexit = false;
        },
    },

    mounted() {
        document.addEventListener('mouseout', (e) => {
            if (!e.relatedTarget && !e.toElement) {
                console.log('mouseout');
                setTimeout(() => {
                    if(!this.isVexitDisable){
                        this.isVexit = true;
                        this.isVexitDisable = true;
                    }
                },100);
            }
        })
    }
};
</script>

