<template>
    <div
        class="video-player"
        :class="{'video_enabled': !setupVideo}"
        @click="setupVideo = true"
    >
        <a
            class="video-player__link"
            v-if="!setupVideo"
            :href="`https://youtu.be/${linkId}`"
            @click.prevent
        >
            <picture>
                <img
                    class="video-player__media"
                    v-if="coverId"
                    :src="`@/src/assets/img/banner.jpg`"
                    alt="Просмотреть наше видео на youtube"
                >
                <img
                    class="video-player__media"
                    v-else
                    :src="`https://i.ytimg.com/vi/NdP1BATCcMk/hqdefault.jpg`"
                    alt="Просмотреть наше видео на youtube"
                >
                <source
                    :srcset="`https://i.ytimg.com/vi_webp/${linkId}/hqdefault.webp`"
                    type="image/webp"
                >
            </picture>
        </a>
        <button
            class="video-player__button"
            v-if="!setupVideo"
            aria-label="Запустить видео"
            type="button"
        ></button>
        <iframe
            class="video-player__media"
            v-if="setupVideo"
            :src="`https://www.youtube.com/embed/${linkId}?rel=0&showinfo=0&autoplay=1`"
            allow="autoplay"
            allowfullscreen
        ></iframe>
    </div>
</template>

<script>
export default {
    name: 'Video',
    props: {
        linkId: {
            type: String,
            default: '0o7HQiw51uw',
        },
        coverId: {
            type: String,
        },
    },

    data() {
        return {
            setupVideo: false,
        };
    },
};
</script>

<style>
.video-player {

    position: relative;
    width: 100%;
    height: 0;
    margin-right: auto;
    margin-left: auto;
    padding-bottom: 75.25%;
    background-color: #222222;

    &:hover .video-player__button {
        background: #f00;
    }

    &_enabled {
        cursor: pointer;
    }

    &_enabled .video-player__button {
        display: block;
    }

    &__link {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &__media {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: none;
        object-fit: cover;
    }

    &__button {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 72px;
        height: 50px;
        z-index: 1;
        display: block;
        padding: 0;
        background: rgb(43, 43, 43);
        border: none;
        border-radius: 50% / 10%;
        transform: translate(-50%, -50%);
        cursor: pointer;
        transition: all 150ms ease-out;

        &::before {
            content: "";
            position: absolute;
            top: 9%;
            right: -5%;
            bottom: 9%;
            left: -5%;
            background: inherit;
            border-radius: 5% / 50%;
        }

        &::after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            width: 0;
            height: 0;
            font-size: 0;
            border-style: solid;
            border-color: transparent transparent transparent #fff;
            transform: translate(-40%, -50%);
            border-width: 11px 0 11px 18px;
        }

        &:focus {
            background: #f00;
            outline: none;
        }
    }
}
</style>
