<template>
    <section class="banner ">
        <div class="banner__bg">
            <div class="wrapper">
                <div class="banner__content ">
                    <div class="banner__info">
                        <h1>
                            Освой digital-профессию с нуля за 2,5 месяца
                        </h1>
                        <div class="banner__description">
                            <p>Для новичков и тех, кто хочет быстро войти в digital-профессию</p>
                            <p>Мы обещаем:</p>
                            <ul>
                                <li>много практики</li>
                                <li>проверку домашних заданий</li>
                                <li>обратная связь от наставника</li>
                                <li>кейсы из практики наших преподавателей</li>
                                <li>никакой воды и бессмысленных лекций</li>
                            </ul>
                        </div>
                        <div class="banner__box">
                            <button
                                class="button button_main banner__button"
                                @click="showModalFree"
                            >Получи бесплатное занятие
                            </button>
                            <button
                                class="button button_additional"
                                @click="showModal"
                            >Запишись на курс
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="banner__mobile"></div>
    </section>
    <Consult
        v-if="isModalConsul"
        @closemodal="closemodal"
    />
    <FreeLesson
        v-if="isModalFreeLesson"
        @closemodalfree="closemodalfree"
    />
</template>

<script>
import FreeLesson from '@/components/PopUp/FreeLesson/FreeLesson';
import Consult from '@/components/PopUp/Consult/Consult';

export default {
    name: 'Banner',

    components: {
        Consult,
        FreeLesson,
    },

    data() {
        return {
            isModalConsul: false,
            isModalFreeLesson: false,
        };
    },

    methods: {
        showModal() {
            this.isModalConsul = true;
        },

        showModalFree() {
            this.isModalFreeLesson = true;
        },

        closemodal() {
            this.isModalConsul = false;
        },

        closemodalfree() {
            this.isModalFreeLesson = false;
        },
    },
};
</script>

<style>

.banner {
    background-color: #2aaae3;
    padding-top: 80px;

    @media (--tablet-only) {
        padding-top: 144px;
    }

    &__bg {

        @media (--tablet-only) {
            max-width: 1440px;
            margin: 0 auto;
            background-image: url("img/banner.jpg");
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }
    }

    &__mobile {
        max-width: 767px;
        height: 656px;
        background-image: url("img/banner-mobile.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        @media (--tablet-only) {
            display: none;
        }
    }

    &__content {
        display: block;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            display: block;
        }
    }

    &__info {
        padding: 32px 0;
        display: flex;
        flex-direction: column;

        @media (--tablet-only) {
            width: 65%;
            padding: 100px 0;
        }

        @media (--desktop-only) {
            width: 50%;
            padding: 100px 0;
        }

        h1 {
            font-family: var(--default);
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 32px;
            color: var(--color-text-additional);

            @media (--tablet-only) {
                font-size: 48px;
                line-height: 56px;
            }
        }
    }

    &__description {
        font-family: var(--default);
        max-width: 435px;
        color: var(--color-text-additional);
        margin-top: 24px;
        margin-bottom: 32px;

        @media (--tablet-only) {
            margin-top: 32px;
            margin-bottom: 48px;
        }

        p {
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
            margin-bottom: 8px;

            @media (--tablet-only) {
                font-size: 24px;
                line-height: 32px;
                margin-bottom: 16px;
            }
        }

        ul {
            display: flex;
            flex-direction: column;
            padding-left: 10px;
        }

        li {
            font-family: var(--default);
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
            color: var(--color-text-additional);
            list-style: disc;
            margin-left: 20px;

            @media (--tablet-only) {
                font-size: 24px;
                line-height: 32px;
                margin-bottom: 4px;
            }
        }
    }

    &__box {

        @media (--tablet-only) {
            display: flex;
        }

        .banner__button:last-child {
            margin-right: 0;
        }
    }

    &__button {
        display: flex;
        align-items: center;
        margin-right: 35px;
        margin-bottom: 24px;

        @media (--tablet-only) {
            margin-bottom: 0;
        }
    }
}
</style>
