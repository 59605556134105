<template>
    <section class="section video">
        <div class="wrapper">
            <header class="header video__header">
                <h2 class="title video__title">Посмотри это видео и узнай</h2>
            </header>
            <div class="video__content">
                <div class="video__content-info">
                    <ul>
                        <li>кто такой интернет-маркетолог и почему 54% маркетологов получают от 80
                            000 руб в месяц
                        </li>
                        <li>какие направления в интернет-маркетинге (SEO, SMM, копирайтинг,
                            таргетинг) наиболее востребованы
                        </li>
                        <li>чем занимается таргетолог и контекстолог</li>
                        <li>нужно ли спецобразование, чтобы усвоить основы дизайна и копирайтинга</li>
                        <li>почему большинство курсов, обещающих быстрые результаты и высокий
                            заработок - это развод
                        </li>
                        <li>как стать интернет-маркетологом и быть неуязвимым для внешних
                            обстоятельств
                        </li>
                    </ul>
                </div>
                <div class="video__content-player">
                    <Player />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Player from '@/components/Player/Player';

export default {
    name: 'Video',
    components: {
        Player,
    },
};
</script>

<style>
.video {
    background: #dbf1fa;

    &.section {
        padding: 80px 0;
    }

    &__header {

        .title::before {
            background-color: #1a8cbd;
            bottom: -15px;
        }
    }

    &__content {
        margin-top: 70px;
        display: flex;
        align-items: center;
        flex-direction: column;

        @media (--desktop-only) {
            flex-direction: initial;
        }
    }

    &__content-info {
        order: 1;
        width: 100%;
        background: #1a8cbd;
        padding: 24px 8px;
        z-index: 1;

        @media (--tablet-only) {
            max-width: 584px;
            padding: 65px 110px 34px 75px;
        }

        @media (--desktop-only) {
            max-width: 690px;
            order: initial;
        }

        ul {
            display: flex;
            flex-direction: column;
            padding-left: 20px;

            .video-item:last-child {
                margin-bottom: 0;
            }
        }

        li {
            max-width: 456px;
            list-style: disc;
            color: var(--color-text-additional);
            margin-bottom: 24px;
            font-family: var(--default);
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 24px;
        }
    }

    &__content-player {
        position: relative;
        max-width: 584px;
        width: 100%;
        z-index: 2;

        @media (--desktop-only) {
            right: 80px;
        }
    }

}
</style>
