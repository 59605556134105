<template>
    <section class="section format">
        <div class="wrapper">
            <header class="header format__header">
                <h2 class="title format__title">Формат обучения</h2>
                <div class="format__box">
                    <button
                        class="button button_main format__button"
                        @click="showModalFree"
                    >Получи 1-й урок бесплатно</button>
                </div>
            </header>
            <div class="format__content">
                <div class="format-card">
                    <div class="format-card__icon"></div>
                    <p>Лекции — ты получишь 5 модулей по 6 лекций. Запись каждой лекции
                        будет доступна на платформе неограниченное время.
                    </p>
                </div>
                <div class="format-card">
                    <div class="format-card__icon"></div>
                    <p>Практика — будешь подробно разбирать маркетинговые инструменты:
                        для чего они и что с ними делать
                    </p>
                </div>
                <div class="format-card">
                    <div class="format-card__icon"></div>
                    <p>Домашние задания — будешь применять знания на практике и получать
                        обратную связь от преподавателя
                    </p>
                </div>
                <div class="format-card">
                    <div class="format-card__icon"></div>
                    <p>Симулятор — ты будешь решать кейсы
                        на реальных проектах с реальными инструментами.
                    </p>
                </div>
                <div class="format-card">
                    <div class="format-card__icon"></div>
                    <p>Тестирование — после каждого смыслового блока ты будешь проходить
                        тест на 10 вопросов, чтобы лучше закрепить материал. В конце каждого модуля тебя ожидает итоговый
                        тест.
                    </p>
                </div>
                <div class="format-card">
                    <div class="format-card__icon"></div>
                    <p>Сертификат — по итогу обучения ты получишь 2 сертификата: на
                        русском и английском языках
                    </p>
                </div>
            </div>
        </div>
    </section>
    <FreeLesson
        v-if="isModalFreeLesson"
        @closemodalfree="closemodalfree"/>
</template>

<script>
import FreeLesson from '@/components/PopUp/FreeLesson/FreeLesson';

export default {
    name: 'Format',

    components: {
        FreeLesson,
    },

    data() {
        return {
            isModalFreeLesson: false,
        }
    },

    methods: {
        showModalFree() {
            this.isModalFreeLesson = true;
        },

        closemodalfree() {
            this.isModalFreeLesson = false;
        },
    }
};
</script>

<style>
.format {
    background: #fbdcac;

    &__header {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;

        @media (--tablet-only) {
            flex-direction: row;
            justify-content: space-between;
        }

        .title:before {
            background-color: var(--card-additional);
            bottom: -10px;

            @media (--tablet-only) {
                bottom: 0;
            }
        }
    }

    &__title {
        margin-bottom: 40px;

        @media (--tablet-only) {
            margin-bottom: 0;
        }
    }

    &__content {
        max-width: 380px;
        width: 100%;
        display: grid;
        row-gap: 30px;
        margin-top: 40px;
        margin-left: auto;
        margin-right: auto;

        @media (--tablet-only) {
            max-width: initial;
            column-gap: 30px;
            row-gap: 30px;
            grid-template-columns: 1fr 1fr;
        }

        @media (--desktop-only) {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    &-card {
        position: relative;
        flex-wrap: wrap;
        padding: 24px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        p {
            font-family: var(--default);
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            margin-top: 110px;
        }

        &::before {
            content: "";
            position: absolute;
            top: 24px;
            left: 24px;
            width: 70px;
            height: 70px;
        }

        &:nth-child(1) {

            &::before {
                background: url("img/04.svg") center/contain no-repeat;
            }
        }

        &:nth-child(2) {

            &::before {
                background: url("img/05.svg") center/contain no-repeat;
            }
        }

        &:nth-child(3) {

            &::before {
                background: url("img/02.svg") center/contain no-repeat;
            }
        }

        &:nth-child(4) {

            &::before {
                background: url("img/01.svg") center/contain no-repeat;
            }
        }

        &:nth-child(5) {

            &::before {
                background: url("img/06.svg") center/contain no-repeat;
            }
        }

        &:nth-child(6) {

            &::before {
                background: url("img/03.svg") center/contain no-repeat;
            }
        }

        &:nth-child(odd) {
            background: #fff;
            color: var(--color-text-main-normal);
        }

        &:nth-child(even) {
            background: var(--card-additional);
            color: var(--color-text-additional);
        }
    }
}
</style>
