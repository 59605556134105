<template>
    <section id="form" class="form">
        <div class="wrapper">
            <div class="form-wrapper">
                <div class="form__content">
                    <div class="form__block">
                        <div class="form__title">Хочешь пробный урок?</div>
                        <div class="form__subtitle">
                            Заполни форму &#8212; и начинай учиться уже сегодня
                        </div>
                        <form class="form__input-block form-input">
                            <input
                                type="text"
                                class="form-input__item"
                                placeholder="Имя"
                            >
                            <input
                                type="tel"
                                class="form-input__item"
                                placeholder="Номер телефона"
                            >
                            <input
                                type="email"
                                class="form-input__item"
                                placeholder="E-mail"
                            >
                            <button type="submit" class="button button_additional">Отправить</button>
                        </form>

                    </div>
                </div>
            </div>
            <div class="form__mobile"></div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'Form',
};
</script>

<style scoped>
.form {
    background-color: #2aaae3;
    padding: 0;

    @media (--tablet-only) {
        background-image: url("img/form.jpg");
        background-repeat: no-repeat;
        background-position: center;
        padding: 65px 0;
    }

    @media (--desktop-only) {
        margin-right: initial;
        margin-left: initial;
    }

    & .wrapper {
        width: 100%;
    }

    &-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 60px 0;

        @media (--tablet-only) {
            justify-content: flex-end;
        }
    }

    &__mobile {
        max-width: 727px;
        height: 420px;
        background-image: url("img/form-mobile.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        @media (--tablet-only) {
            display: none;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        max-width: 448px;
        width: 100%;

        @media (--tablet-only) {
            margin-right: 50px;
        }
    }

    &__title {
        font-family: var(--default);
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        color: var(--color-text-additional);
        margin-bottom: 16px;

        @media (--tablet-only) {
            font-size: 34px;
            line-height: 40px;
            margin-bottom: 24px;
        }
    }

    &__subtitle {
        font-family: var(--default);
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        color: var(--color-text-additional);

        @media (--tablet-only) {
            font-size: 34px;
            line-height: 40px;
        }
    }

    &__input-block {
        margin-top: 48px;
        display: flex;
        flex-direction: column;
    }

    .form-input {

        &__item {
            margin-bottom: 32px;
            background-color: transparent;
            border-bottom: 2px solid #fff;
            font-family: var(--default);
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            color: #fff;
            padding: 10px 5px;
            outline: none;
            transition: 0.3s;

            &:hover,
            &:focus {
                border-bottom: 2px solid #81CEEE;
            }
        }

        &__item:nth-child(3){
            margin-bottom: 50px;
        }
    }
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #fff;
}

::-moz-placeholder { /* Firefox 19+ */
    color: #fff;
}

:-ms-input-placeholder { /* IE 10+ */
    color: #fff;
}

:-moz-placeholder { /* Firefox 18- */
    color: #fff;
}
</style>
