<template>
    <footer class="footer">
        <div class="footer__box">
            <div class="wrapper">
                <div class="footer__content">
                    <div class="footer__container">
                        <div class="footer__logo-container">
                            <div class="footer__logo"></div>
                            <div class="footer__subtitle">Школа цифрового маркетинга</div>
                        </div>
                        <div class="footer__nav-wrapper">
                            <div class="footer__nav-item">
                                <a
                                    href="./Модули_курса.pdf"
                                    target="_blank"
                                >Учебная программа</a>
                            </div>
                            <div class="footer__nav-item">
                                <a
                                    href="./Публичная_оферта.pdf"
                                    target="_blank"
                                >Договор оферты</a>
                            </div>
                            <div class="footer__nav-item">
                                <a
                                    href="./Политика_конфиденциальности.pdf"
                                    target="_blank"
                                >Политика конфиденциальности</a>
                            </div>
                            <div class="footer__nav-item nohover">
                                <a
                                    href="tel:+79310096916"
                                    class="footer__link_tel"
                                >+7 (931) 009-69-16</a>
                                <a
                                    href="mailto:info@digiskills.ru"
                                    class="footer__link_email"
                                >info@digiskills.ru</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer__money">
            <div class="footer__money-icon footer__money-icon_1"></div>
            <div class="footer__money-icon footer__money-icon_2"></div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'Vfooter',
};
</script>

<style>
.footer {
    position: relative;
    display: flex;
    flex-direction: column;

    &__box {
        background-color: #f29200;
        padding: 36px 0;
    }

    &__container {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;

        @media (--tablet-only) {
            flex-direction: column;
        }

        @media (--desktop-only) {
            justify-content: space-between;
            flex-direction: initial;
        }
    }

    &__logo-container {
        width: 100%;
        flex-shrink: 0;
        margin-bottom: 32px;

        @media (--tablet-only) {
            margin: 0 auto 18px auto;
            max-width: 160px;
        }

        @media (--desktop-only) {
            max-width: 160px;
            margin: initial;
        }
    }

    &__logo {
        width: 160px;
        height: 56px;
        background-image: url("img/logo-footer.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }

    &__subtitle {
        margin-top: 10px;
        margin-bottom: 18px;
        font-family: var(--default);
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 13px;
        color: #fff;
    }

    &__social-link {
        display: block;
        width: 32px;
        height: 32px;

        &::before {
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            background-image: url("img/icon-inst.svg");
        }
    }

    &__nav-wrapper {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column-reverse;

        @media (--tablet-only) {
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;
        }

        @media (--desktop-only) {
            width: calc(100% - 160px - 130px);
        }

        .footer__nav-item:last-child {
            display: flex;
            flex-direction: column;

            @media (--tablet-only) {
                align-items: flex-end;
            }
        }
    }

    &__nav-item {
        flex-grow: 1;
        width: 100%;
        margin-bottom: 16px;
        font-family: var(--default);
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: #fff;
        cursor: pointer;
        transition: 0.3s;

        @media (--tablet-only) {
            width: initial;
            padding-left: 10px;
            padding-right: 10px;
        }

        &:not(.nohover):hover {
            text-decoration: underline;
        }

        a {
            color: #fff;
        }
    }

    &__link_tel {
        margin-bottom: 16px;

        @media (--tablet-only) {
            margin-bottom: 8px;
        }

        &:hover {
            text-decoration: underline;
        }
    }

    &__link_email {

        &:hover {
            text-decoration: underline;
        }
    }

    &__money {
        display: flex;
        justify-content: center;
        padding-top: 18px;
        padding-bottom: 24px;
        background-color: #eaeaea;
    }

    &__money-icon {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    &__money-icon_1 {
        width: 85px;
        height: 27px;
        margin-right: 36px;
        background-image: url("img/money-visa-mc.svg");
    }

    &__money-icon_2 {
        width: 142px;
        height: 30px;
        background-image: url("img/money.svg");
    }
}
</style>
