import { createApp } from 'vue'
import App from './App.vue'
import VueSplide from '@splidejs/vue-splide';
import './assets/css/style.css';

var VueScrollTo = require('vue-scrollto');

const app = createApp(App)
app.use(VueSplide)
app.use(VueScrollTo)

app.mount('#app')
