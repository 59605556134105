<template>
    <section class="section questions">
        <div class="wrapper">
            <header class="header questions__header">
                <h2 class="title questions__title">Появились вопросы?</h2>
                <p class="questions__subtitle">Как проходят занятия, что необходимо для занятия и другие частые
                    вопросы</p>
            </header>
            <div class="questions__content questions-modul">
                <div class="questions-modul-list">
                    <div
                        :class="['questions-modul-item', {'active': item.show}]"
                        @click="item.show=!item.show"
                        v-for="item in questionsList"
                        :key="item.id"
                    >
                        <div class="questions-modul__box">
                            <div class="questions-modul-icon"></div>
                            <div class="questions-modul-text">
                                <div class="questions-modul-text__title">{{ item.title }}</div>
                            </div>
                        </div>
                        <transition name="fade">
                            <div
                                class="questions-inner"
                                v-if="item.show"
                            >
                                <div class="questions-inner__list">
                                    <div class="questions-inner__item">{{ item.subtitle }}</div>
                                </div>
                            </div>
                        </transition>
                    </div>
                </div>
            </div>
            <div class="questions__box">
                <p class="questions__text-link">Остались вопросы? Задавай их по почте или
                    <span @click="showModal">свяжись с нами</span></p>
            </div>
        </div>
    </section>
    <Consult
        v-if="isModalConsul"
        @closemodal="closemodal"
    />
</template>

<script>
import Consult from '@/components/PopUp/Consult/Consult';

export default {
    name: 'Programm',

    components: {
        Consult,
    },

    data() {
        return {
            questionsList: [
                {
                    id: 1,
                    title: 'Я новичок. Курсы подойдут для меня?',
                    subtitle: 'Да, подойдут. Наш курс интернет-маркетинга рассчитан на совсем новичков или тех, у кого есть пробелы и неупорядоченная информация в голове, поэтому если вы умеете включать компьютер и у вас есть желание, то вы научитесь. Перед каждым практическим заданием мы проходим базовые вещи и затрагиваем все теоретические и практические моменты. Курсы школы интернет-маркетинга «Digital Skill» дают понимание работающего на результат интернет-маркетинга и делают вас ближе к гуру маркетинга. Мы не используем «тяжёлые» и очень сложные или дорогие сервисы, а заменяем их менее дорогими или бесплатными аналогами с тем же функционалом.',
                    show: false,
                },
                {
                    id: 2,
                    title: 'Я хочу изучать только одно направление интернет-маркетинга на ваших курсах (SMM, web-аналитика, SEO и т.д.). Можно ли мне не проходить полный курс, а пройти только один модуль?',
                    subtitle: 'Курс необходимо проходить полностью. Частичная покупка курса невозможна, так как ценность модулей в последовательном изложении материала и практическими заданиями, которые связаны между собой в рамках курса. Вы можете не высылать преподавателю проверочные работы по каким-то модулям, если не хотите.',
                    show: false,
                },
                {
                    id: 3,
                    title: 'Я живу не в России. Я могу пройти ваш курс?',
                    subtitle: 'Конечно! Наш курс предполагает обучение онлайн из любой страны, главные условия – наличие интернет и возможность оплаты через кошелек ЮMoney (для России, Беларуси, Украины и некоторых других стран).  Для удобной оплаты мы создали кошелек ЮMoney, на который можно делать перевод с банковских карт, поэтому никаких проблем быть не должно.',
                    show: false,
                },
                {
                    id: 4,
                    title: 'Как проходят занятия на курсах интернет-маркетинга?',
                    subtitle: 'После начала курса, мы открываем вам последовательный доступ к занятиям и материалам на обучающей платформе. Так вы планируете своё свободное время, работаете с материалами урок за уроком и через 14 недель заканчиваете все модули выбранного курса.',
                    show: false,
                },
                {
                    id: 5,
                    title: 'Из чего состоят занятия?',
                    subtitle: 'Каждое занятие состоит из видеолекций, текстового описания, подготовленного к скачиванию и распечатке (в этом описании приводится краткий конспект виедолекции), домашних и практических заданий. Завершает каждое занятие проверочное практическое задание и/или тест по пройденному материалу.',
                    show: false,
                },
                {
                    id: 6,
                    title: 'Если по уважительным причинам я не смогу проходить курс, можно будет его перенести?',
                    subtitle: 'Да, в таких случаях мы решаем вопросы индивидуально и подключаем студентов позже, к другим группам.',
                    show: false,
                },
                {
                    id: 7,
                    title: 'Что мне понадобится для курсов?',
                    subtitle: 'Для прохождения курсов вам понадобится компьютер, планшет или смартфон с доступом к интернету, а также желание получать реальные знания и использовать их на практике. Если у вас уже есть идея или свой проект – вы сразу сможете его развивать.',
                    show: false,
                },
                {
                    id: 8,
                    title: 'А если у меня пока нет своего проекта, на котором я смогу учиться?',
                    subtitle: 'Не беспокойтесь, мы всегда придем на помощь и предоставим свой учебный проект: вы протестите все гипотезы, примените все знания, проверите всё на практике.',
                    show: false,
                },
                {
                    id: 9,
                    title: 'Найду ли я работу после курсов?',
                    subtitle: 'Загляните на хаха.ру и другие сайты - вакансий в сфере цифрового маркетинга больше, чем желающих. А если у вас есть своя бизнес-идея, то вы стразу же можете работать над ее воплощением.',
                    show: false,
                },
                {
                    id: 10,
                    title: 'А если мне понадобится помощь?',
                    subtitle: 'Наши преподаватели всё время на связи, в том числе и вне занятий. Учитесь ли вы сейчас или уже окончили курс (неважно, как давно) - вы сможете позвонить, написать, переспросить.',
                    show: false,
                },
                {
                    id: 11,
                    title: 'Сколько времени я потрачу на прохождение курса?',
                    subtitle: 'Рекомендуем планировать на каждый модуль одну неделю. Мы предполагаем, что вы будете отводить для занятий 1–2 часа в будний день, и все свое свободное время в выходные. Конечно, насколько быстро у вас всё будет получаться сильно зависит от вашего упорства, но отведённого времени должно быть достаточно, насколько сильно вы бы не были заняты другими делами. Мы не рассчитываем, что вы всё бросите для прохождения нашего онлайн-курса.',
                    show: false,
                },
                {
                    id: 12,
                    title: 'Я получу сертификат после прохождения курса?',
                    subtitle: 'Мы уверены, что в нашем деле портфолио работ лучше любого сертификата. Но, несмотря на это, участники, которые успешно сдали все работы куратору, ответственно и активно работали на курсе, получат сертификат об успешном прохождении курса.',
                    show: false,
                },
            ],
            isModalConsul: false,
        };
    },

    methods: {
        showModal() {
            this.isModalConsul = true;
        },

        closemodal() {
            this.isModalConsul = false;
        },
    },
};
</script>

<style>
.questions {

    &__header {
        display: flex;
        flex-direction: column;

        .title:before {
            background-color: var(--card-main);
            bottom: -15px;
        }
    }

    &__subtitle {
        font-family: var(--default);
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.005em;
        color: var(--color-text-main);
        margin-top: 56px;
    }

    &__box {
        display: flex;
    }

    &__text-link {
        font-family: var(--default);
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 21px;
        color: var(--color-text-main-normal);

        @media (--tablet-only) {
            font-size: 18px;
        }

        span {
            color: var(--card-main);
            transition: 0.2s;
            cursor: pointer;

            &:hover {
                color: #b15c12;
                text-decoration-line: underline;
            }
        }
    }

    &__content {
        margin: 40px 0;
    }
}

.questions-modul {

    &-list {
        display: flex;
        flex-direction: column;

        .questions-modul-item:last-child {
            margin-bottom: 0;
        }
    }

    &-item {
        display: flex;
        margin-bottom: 24px;
        flex-direction: column;
        cursor: pointer;

        &.active {

            .questions-modul-text::before {
                transform: rotate(180deg) translateY(50%);
            }
        }

        &:hover {

            .questions-modul-text__title {
                color: #ec940f;
            }
        }
    }

    &__box {
        display: flex;
        width: 100%;
    }

    &-text {
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        padding: 12px 48px 12px 16px;
        font-family: var(--default);
        font-style: normal;
        font-weight: 300;
        font-size: 20px;
        line-height: 23px;
        background: #fdf0dd;
        transition: 0.2s;

        @media (--tablet-only) {
            padding: 28px 65px 28px 28px;
        }

        &::before {
            content: "";
            position: absolute;
            display: block;
            top: 14px;
            right: 18px;
            width: 20px;
            height: 12px;
            background-image: url("img/arrow-orang.svg");
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            transition: 0.2s;

            @media (--tablet-only) {
                right: 28px;
                width: 24px;
                height: 15px;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        &__title {
            width: 100%;
            font-family: var(--default);
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: var(--color-text-main);
            transition: 0.2s;

            @media (--tablet-only) {
                font-size: 20px;
                line-height: 32px;
            }
        }
    }

    &-icon {
        max-width: 40px;
        width: 100%;
        flex-shrink: 0;
        background-color: #f9c77c;;
        position: relative;

        @media (--tablet-only) {
            max-width: 80px;
            width: 100%;
        }

        &::before {
            content: "";
            position: absolute;
            top: 8px;
            left: 50%;
            transform: translateX(-50%);
            width: 14px;
            height: 23px;
            display: block;
            background-image: url("img/question.svg");
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;

            @media (--tablet-only) {
                width: 28px;
                height: 46px;
                top: 50%;
                transform: translateY(-50%) translateX(-50%);
            }
        }
    }

    .questions-inner {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
        background: #fff;
        box-shadow: 8px 8px 10px rgba(236, 148, 15, 0.2);
        padding: 24px;
        margin-top: 8px;
        margin-bottom: 32px;
        transition: 0.2s;

        &__list {
            display: flex;
            flex-direction: column;
            margin-left: 15px;
        }

        &__item {
            font-family: var(--default);
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 0.005em;
            color: var(--color-text-main-normal);
            margin-bottom: 8px;
        }
    }
}

.fade-enter-active, .fade-leave-active {
    transition: opacity .3s;
}

.fade-enter-from, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */
{
    opacity: 0;
}
</style>
