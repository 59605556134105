<template>
    <section class="section programm">
        <div id="program" style="position:relative; top:-170px;"></div>
        <div class="wrapper">
            <header class="header programm__header">
                <h2 class="title programm__title">Учебная программа</h2>
                <div class="programm__box">
                    <div class="button button_main programm__button-full">
                        <a
                            href="./Модули_курса.pdf"
                            download
                        >Скачай программу</a>
                    </div>
                    <button
                        class="button programm__button-transparent"
                        @click="showModal"
                    >Получи консультацию
                    </button>
                </div>
            </header>
            <div
                :class="['programm__content modul', {'programm__content_active':isModulItemAll}]"
            >
                <div class="modul-list">
                    <div
                        :class="['modul-item', {'active': item.show}]"
                        @click="item.show=!item.show"
                        v-for="item in moduleList"
                        :key="item.id"

                    >
                        <div class="modul-text">
                            <div class="modul-text__number">{{ item.name }}</div>
                            <div class="modul-text__title">{{ item.title }}</div>
                        </div>
                        <transition name="fade">
                            <div
                                class="modul-inner"
                                v-if="item.show"
                            >
                                <ul>
                                    <li
                                        v-for="item in item.list"
                                        :key="item.message"
                                    >
                                        {{ item.message }}</li>
                                </ul>
                            </div>
                        </transition>
                    </div>
                </div>
            </div>
            <div class="programm__block">
                <div
                    class="programm__all"
                    v-bind:class="{ 'block_up': isModulItemAll }"
                    @click="isModulItemAll=!isModulItemAll"
                >Показать все модули
                </div>
            </div>
        </div>
    </section>
    <Consult
        v-if="isModalConsul"
        @closemodal="closemodal"
    />
</template>

<script>

import Consult from '@/components/PopUp/Consult/Consult';

export default {
    name: 'Programm',

    components: {
        Consult,
    },

    data() {
        return {
            moduleList: [
                {
                    id: 1,
                    name: 'Модуль 1',
                    title: 'Интерент-маркетинг с чего начать',
                    show: false,
                    list: [
                        { message: 'Целевая аудитория, принципы сегментации' },
                        { message: 'Создание аватара клиента' },
                        { message: 'Customer Journey Map (карта пути пользователя)' },
                        { message: 'Воронка продаж' },
                        { message: 'Позиционирование и создание выгод' },
                        { message: 'Разработка Уникального Торгового Предложения (УТП)' },
                        { message: 'Анализ конкурентов и рынков' },
                        { message: 'Показатели эффективности в digital-маркетинге: метрики, KPI' },
                    ],
                },
                {
                    id: 2,
                    name: 'Модуль 2',
                    title: 'Основы SEO-продвижения',
                    show: false,
                    list: [
                        { message: 'Место SEO в интернет-маркетинге' },
                        { message: 'Поисковые системы и типы запросов' },
                        { message: 'Факторы ранжирования' },
                        { message: 'Методы внутренней и внешней оптимизации сайта' },
                        { message: 'Инструменты SEO-специалиста: Яндекс.Вебмастер, Google Search Console, Яндекс.Метрика, Google Analytics, Screamingfrog, Excel, Key Collector' },
                        { message: 'Разработка стратегии продвижения, плана работ и технических заданий' },
                    ],
                },
                {
                    id: 3,
                    name: 'Модуль 3',
                    title: 'Прикладной копирайтинг без лишних слов',
                    show: false,
                    list: [
                        { message: 'Что такое этот ваш копирайтинг' },
                        { message: 'Что копирайтеру нужно знать о SEO' },
                        { message: 'Как новичку начать писать тексты: главные правила' },
                        { message: 'Пошаговый план работы над текстом' },
                        { message: 'Как написать понятный текст' },
                        { message: 'Как написать продающий текст' },
                    ],
                },
                {
                    id: 4,
                    name: 'Модуль 4',
                    title: 'Разработка посадочных страниц и лендингов',
                    show: false,
                    list: [
                        { message: 'Продукт, сегментация целевой аудитории и офер' },
                        { message: 'Базовые принципы создания лендинга' },
                        { message: 'Текстовый контент лендинга' },
                        { message: 'Дизайн и графические составляющие лендинга' },
                        { message: 'Работа с графическими инструментами' },
                    ],
                },
                {
                    id: 5,
                    name: 'Модуль 5',
                    title: 'Контекстная реклама',
                    show: false,
                    list: [
                        { message: 'Основные понятия (CTR, PPC, PPA и др.)' },
                        { message: 'Виды контекстной рекламы' },
                        { message: 'Ключевые слова и структура объявлений' },
                        { message: 'UTM-метки' },
                        { message: 'Структура РК' },
                        { message: 'Бюджетирование и ставки' },
                        { message: 'Стратегия по инструментам' },
                        { message: 'Бенчмаркинг' },
                        { message: 'Модели атрибуции' },
                    ],
                },
                {
                    id: 6,
                    name: 'Модуль 6',
                    title: 'SMM на практике для новичков',
                    show: false,
                    list: [
                        { message: 'Упаковка социальных сетей: концепция и позиционирование групп и аккаунтов' },
                        { message: 'Особенности контента для соцсетей, контент-план' },
                        { message: 'Продвижение и реклама в социальных сетях' },
                        { message: 'Комьюнити-менеджмент: как правильно общаться с подписчиками' },
                    ],
                },
                {
                    id: 7,
                    name: 'Модуль 7',
                    title: 'Email-маркетинг',
                    show: false,
                    list: [
                        { message: 'Способы сбора и сегментации базы для рассылок' },
                        { message: 'Триггерные рассылки' },
                        { message: 'Шаблоны писем' },
                        { message: 'Основные KPI' },
                        { message: 'Сервисы для работы с e-mail-рассылками' },
                    ],
                },
                {
                    id: 8,
                    name: 'Модуль 8',
                    title: 'Веб-аналитика для новичков',
                    show: false,
                    list: [
                        { message: 'Для чего нужна веб-аналитика' },
                        { message: 'Google Analytics и Яндекс.Метрика: установка на сайт и настройка отчетов' },
                        { message: 'Работа с Google Tag Manager' },
                        { message: 'A/B Тестирование' },
                        { message: 'Визуализация аналитических данных в Google Data Studio' },
                    ],
                },
                {
                    id: 9,
                    name: 'Бонусный модуль',
                    title: 'Youtube: ПРОдвижение-2021',
                    show: false,
                    list: [
                        { message: 'Создаем канал на Youtube: идея, контент, продвижение' },
                        { message: 'Как подготовиться к съемке видео для Youtube' },
                        { message: 'Как и где продвигать видео?' },
                        { message: 'Монетизация своего канала' },
                    ],
                },
            ],
            isModalConsul: false,
            isModulItemAll: false,
        };
    },

    methods: {
        showModal() {
            this.isModalConsul = true;
        },

        closemodal() {
            this.isModalConsul = false;
        },
    },
};
</script>

<style>

.programm {

    &__header {

        @media (--tablet-only) {
            display: flex;
            flex-direction: column;
        }

        @media (--desktop-only) {
            justify-content: space-between;
            flex-wrap: wrap;
            flex-direction: row;
        }

        .title::before {
            background-color: #29abe2;
            bottom: -8px;

            @media (--tablet-only) {
                bottom: -16px;
            }

            @media (--desktop-only) {
                bottom: 0;
            }
        }
    }

    &__title {
        margin-right: 10px;
        margin-bottom: 40px;

        @media (--tablet-only) {
            margin-bottom: 0;
        }
    }

    &__box {

        @media (--tablet-only) {
            display: flex;
            margin-top: 40px;
        }

        @media (--desktop-only) {
            justify-content: flex-end;
            margin-top: 0;
        }

        & .button {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &__block {
        display: flex;
        justify-content: center;
    }

    &__all {
        position: relative;
        font-family: var(--default);
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 18px;
        color: #136b90;
        cursor: pointer;
        transition: 0.3s;
        float: left;

        &:hover {
            text-decoration: underline;
        }

        &.block_up {
            &::before {
                transform: translateY(-50%) rotate(180deg);
            }
        }

        &::before {
            content: "";
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: -17px;
            display: block;
            width: 10px;
            height: 14px;
            background-image: url("img/arrow.svg");
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            transition: 0.3s;
        }
    }

    &__button-full {
        flex-shrink: 0;
        margin-right: 30px;
        margin-bottom: 14px;

        @media (--tablet-only) {
            margin-bottom: 0;
        }

        a {
            color: #fff;
        }
    }

    &__button-transparent {
        flex-shrink: 0;
        background: transparent;
        border: 2px solid #29abe2;
        color: #29abe2;
        transition: 0.3s;

        &:hover {
            color: #aedff4;
            border: 2px solid #aedff4;
        }
    }
}

.modul {

    &-list {
        display: flex;
        flex-direction: column;

        .modul-item:last-child {
            margin-bottom: 0;
        }

        .modul-item:nth-child(-n+3) {
            display: block;
        }

        .modul-item:nth-child(n+4) {
            display: none;
        }

    }

    &-item {
        display: flex;
        flex-direction: column;
        margin-bottom: 24px;
        cursor: pointer;

        @media (--tablet-only) {
            margin-bottom: 16px;
        }

        &.active {

            .modul-text::before {
                transform: rotate(180deg) translateY(50%);
            }
        }
    }

    &-text {
        width: 100%;
        position: relative;
        padding: 8px 40px 16px 16px;
        font-family: var(--default);
        font-style: normal;
        font-weight: 300;
        font-size: 20px;
        line-height: 23px;
        background: #dbf1fa;

        @media (--tablet-only) {
            display: flex;
            align-items: center;
            padding: 16px 32px;
        }

        &:before {
            content: "";
            position: absolute;
            display: block;
            top: 15px;
            right: 18px;
            width: 20px;
            height: 12px;
            background-image: url("img/arrow.svg");
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            transition: 0.2s;

            @media (--tablet-only) {
                width: 24px;
                height: 14px;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        &__number {
            width: 160px;
            flex-shrink: 0;
            color: #136b90;
            position: relative;
            font-family: var(--default);
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 23px;
            margin-bottom: 30px;

            @media (--tablet-only) {
                margin-bottom: 0;
                font-size: 20px;
                line-height: 48px;
            }

            &::before {
                display: none;

                @media (--tablet-only) {
                    content: "";
                    position: absolute;
                    display: block;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 0;
                    width: 2px;
                    height: 48px;
                    background-color: #00709d;
                    margin: 0 32px;
                }
            }

            &::after {
                content: "";
                display: block;
                position: absolute;
                left: 0;
                bottom: -12px;
                height: 2px;
                width: 72px;
                background-color: #136B90;

                @media (--tablet-only) {
                    display: none;
                }
            }
        }

        &__title {
            width: 100%;
            color: var(--color-text-main);
            font-family: var(--default);
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            transition: 0.2s;

            @media (--tablet-only) {
                font-size: 20px;
                line-height: 32px;
            }

            &:hover {
                color: #136b90;
            }
        }
    }

    .modul-inner {
        display: flex;
        flex-direction: column;
        width: 100%;
        background: #fff;
        box-shadow: 4px 4px 10px rgba(0, 112, 157, 0.2);
        padding: 24px 16px;
        margin-top: 8px;
        margin-bottom: 32px;

        @media (--tablet-only) {
            padding: 32px;
        }
    }

    .modul-inner > ul, ol {
        display: flex;
        flex-direction: column;
        padding-left: 30px;

        @media (--tablet-only) {
            padding-left: 30px;
        }
    }

    .modul-inner > * {

        li, p {
            list-style: disc;
            font-family: var(--default);
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: var(--color-text-main-normal);
            margin-bottom: 4px;

            @media (--tablet-only) {
                margin-bottom: 8px;
            }
        }
    }
}

.programm__content {
    margin: 40px 0 20px;

    @media (--tablet-only) {
        margin: 40px 0 43px;
    }

    @media (--desktop-only) {
        margin: 70px 0 43px;
    }

    &_active {

        .modul-item:nth-child(n+4) {
            display: block;
        }
    }
}

.fade-enter-active, .fade-leave-active {
    transition: opacity .3s;
}

.fade-enter-from, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */
{
    opacity: 0;
}
</style>
