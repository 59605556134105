<template>
    <div>
        <div class="modal modal-error">
            <button
                class="btn-close-modal"
                @click="closemodal"
            ></button>
            <div class="modal-error__content">
                <div class="modal-error__info">
                    <div class="modal-error__icon"></div>
                    <div class="modal-error__block">
                        <p class="modal-error__text">
                            Упс... Что-то пошло не так
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Error',

    methods: {
        closemodal() {
            this.$emit('closemodal');
        },
    },
};
</script>

<style>
.modal-error {
    position: fixed;
    max-width: 520px;
    width: 100%;
    background-color: #fff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
    z-index: 100;

    &__content {
        padding: 50px 48px;
    }

    &__info {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        background-color: #fff;
    }

    &__block {
        width: 100%;
    }

    &__text {
        font-family: var(--default);
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.005em;
        color: #333;
        white-space: nowrap;
    }

    &__icon {
        content: "";
        width: 64px;
        height: 64px;
        display: block;
        background-image: url("img/error.svg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        margin-bottom: 48px;
        margin-top: 32px;
    }
}
</style>
